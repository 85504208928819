import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import AOS from 'aos';
import * as Sentry from '@sentry/browser';

import AppSwitch from 'containers/AppSwitch';

AOS.init({
    duration: 600,
    delay: 200,
    once: true,
});

if (process.env.SENTRY_DSN) {
    Sentry.init({ dsn: process.env.SENTRY_DSN });
}

const Main = () => (
    <Router>
        <Route component={AppSwitch} />
    </Router>
);

ReactDOM.render(<Main />, document.getElementById('main'));
