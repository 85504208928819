import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

const MainLayout = loadable(() => import('layouts/MainLayout'));
const ModalLayout = loadable(() => import('layouts/ModalLayout'));
const VacancyDetail = loadable(() => import('containers/Vacancy/Detail'));
const VacancyOverview = loadable(() => import('containers/Vacancy/Overview'));
const ContactPage = loadable(() => import('containers/Contact'));
const ContactForm = loadable(() => import('containers/ContactForm'));
const NotFound = loadable(() => import('containers/NotFound'));
const Legal = loadable(() => import('containers/Legal'));
const Portfolio = loadable(() => import('containers/Portfolio'));

const AppSwitch = ({ history, location }) => {
    const previousLocation = useRef(location);

    useEffect(() => {
        if (history.action !== 'POP' && (!location.state || !location.state.modal)) {
            previousLocation.current = location;
        }
    }, [history, location]);

    const showModal = !!(
        location.state &&
        location.state.modal &&
        previousLocation.current !== location
    ); // not initial render

    return (
        <>
            <Switch location={showModal ? previousLocation.current : location}>
                <Route
                    path="/vacancy/:slug"
                    exact
                    render={(props) => (
                        <MainLayout>
                            <VacancyDetail {...props} />
                        </MainLayout>
                    )}
                />
                <Route
                    path="/"
                    exact
                    render={(props) => (
                        <MainLayout>
                            <VacancyOverview {...props} />
                        </MainLayout>
                    )}
                />
                <Route
                    path="/contact/:slug"
                    exact
                    render={(props) => (
                        <MainLayout>
                            <ContactForm {...props} />
                        </MainLayout>
                    )}
                />
                <Route
                    path="/contact/"
                    exact
                    render={(props) => (
                        <MainLayout>
                            <ContactPage {...props} />
                        </MainLayout>
                    )}
                />
                <Route
                    path="/cookie-policy/"
                    exact
                    render={(props) => (
                        <MainLayout>
                            <Legal page="cookiePolicy" {...props} />
                        </MainLayout>
                    )}
                />
                <Route
                    path="/disclaimer/"
                    exact
                    render={(props) => (
                        <MainLayout>
                            <Legal page="disclaimer" {...props} />
                        </MainLayout>
                    )}
                />
                <Route
                    path="/terms-and-conditions/"
                    exact
                    render={(props) => (
                        <MainLayout>
                            <Legal page="termsConditions" {...props} />
                        </MainLayout>
                    )}
                />
                <Route
                    path="/privacy-policy/"
                    exact
                    render={(props) => (
                        <MainLayout>
                            <Legal page="privacyPolicy" {...props} />
                        </MainLayout>
                    )}
                />
                <Route
                    path="/portfolio/:slug"
                    exact
                    render={(props) => (
                        <MainLayout>
                            <Portfolio {...props} />
                        </MainLayout>
                    )}
                />
                <Route
                    render={() => (
                        <MainLayout>
                            <NotFound />
                        </MainLayout>
                    )}
                />
            </Switch>
            {showModal ? (
                // Modal routes here...
                // These are popping up above the current page
                <Switch>
                    <Route
                        path="/vacancy/:slug"
                        exact
                        render={(props) => (
                            <ModalLayout {...props}>
                                <VacancyDetail {...props} />
                            </ModalLayout>
                        )}
                    />
                </Switch>
            ) : null}
        </>
    );
};

AppSwitch.propTypes = {
    history: PropTypes.shape({
        action: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default AppSwitch;
